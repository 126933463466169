import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/SearchBox.css';

const SearchBox = () => {
	const history = useNavigate();
	const [keyword, setKeyword] = useState('');

	const submitHandler = (e) => {
		e.preventDefault();
		if (keyword.trim()) {
			history(`/search/${keyword}`);
		} else {
			history('/');
		}
	};
	return (
		<div className='search-box-container'>
			<div className='search-box'>
				<form onSubmit={submitHandler}>
					<div className='search-btn-container'>
						<button type='submit'>
							<i className='fas fa-search'></i>
						</button>
					</div>
					<div className='keyword-field'>
						<input
							type='text'
							name='q'
							onChange={(e) => setKeyword(e.target.value)}
							placeholder='Search Products...'
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SearchBox;
