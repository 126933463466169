import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listOrders } from '../actions/orderActions';
import '../css/OrderListScreen.css';

const OrderListScreen = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const sortTypes = ['Sort By...', 'createdAt', 'id', 'users', 'totalPrice'];
	const orderList = useSelector((state) => state.orderList);
	const { loading, error, orders } = orderList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const [sortedOrders, setSortedOrders] = useState([]);
	const [sortType, setSortType] = useState('');

	useEffect(() => {
		const sortArray = (type) => {
			const types = {
				createdAt: 'createdAt',
				id: '_id',
				users: 'user.name',
				totalPrice: 'totalPrice',
			};
			const sortProperty = types[type];
			const sorted = [].concat(orders).sort((a, b) => {
				if (sortProperty === 'createdAt') {
					return new Date(b[sortProperty]) - new Date(a[sortProperty]);
				} else if (sortProperty === 'users') {
					return b.user.name - a.user.name;
				}
				return b[sortProperty] - a[sortProperty];
			});
			setSortedOrders(sorted);
		};
		if (!userInfo.isAdmin) {
			history('/login');
		}
		if (orders.length !== 0) {
			sortArray(sortType);
		} else {
			dispatch(listOrders());
		}
	}, [dispatch, history, userInfo.isAdmin, sortType]);

	return (
		<>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : orders ? (
				<div className='order-table-container'>
					<h1>Orders</h1>
					<div className='input-field'>
						<select onChange={(e) => setSortType(e.target.value)}>
							{sortTypes.map((x) => (
								<option key={x} value={x} defaultValue={sortType}>
									{x}
								</option>
							))}{' '}
						</select>
					</div>
					<table className='order-table'>
						<thead>
							<tr>
								<th>USER</th>
								<th>CREATE AT</th>
								<th>TOTAL PRICE</th>
								<th>PAYMENT METHOD</th>
								<th>PAID</th>
								<th>DELIVERED</th>
								<th>ID</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{sortedOrders.map((order) => (
								<tr key={order._id}>
									<td>{order.user.name}</td>
									<td>{order.createdAt.substring(0, 10)}</td>
									<td>KSh {order.totalPrice}</td>
									<td>{order.paymentMethod}</td>
									<td>
										{order.isPaid ? (
											order.paidAt.substring(0, 19)
										) : (
											<i className='fas fa-times' style={{ color: 'red' }}></i>
										)}
									</td>
									<td>
										{order.isDelivered ? (
											order.deliveredAt.substring(0, 10)
										) : (
											<i className='fas fa-times' style={{ color: 'red' }}></i>
										)}
									</td>
									<td>{order._id}</td>
									<td>
										<a href={`/order/${order._id}`}>
											<div className='order-details-btn'>
												<button variant='primary'>Details</button>
											</div>
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<Message variant='danger'>NO ORDERS TO LIST</Message>
			)}
		</>
	);
};

export default OrderListScreen;
