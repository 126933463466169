import { createReducer } from '@reduxjs/toolkit';
import {
	USER_DETAILS_FAILURE,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_RESET,
	USER_LOGIN_FAILURE,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_REGISTER_FAILURE,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_UPDATE_PROFILE_FAILURE,
	USER_UPDATE_PROFILE_REQUEST,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAILURE,
	USER_LIST_RESET,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAILURE,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAILURE,
	USER_UPDATE_RESET,
} from '../constants/userConstants';

export const userLoginReducer = createReducer({ userInfo: {} }, (builder) => {
	builder
		.addCase(USER_LOGIN_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(USER_LOGIN_SUCCESS, (state, action) => {
			return { loading: false, userInfo: action.payload };
		})
		.addCase(USER_LOGIN_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addCase(USER_LOGOUT, (state) => {
			return { userInfo: {} };
		})
		.addDefaultCase((state) => {
			return state;
		});
});
export const userRegisterReducer = createReducer(
	{ userInfo: {} },
	(builder) => {
		builder
			.addCase(USER_REGISTER_REQUEST, (state) => {
				return { loading: true };
			})
			.addCase(USER_REGISTER_SUCCESS, (state, action) => {
				return { loading: false, userInfo: action.payload };
			})
			.addCase(USER_REGISTER_FAILURE, (state, action) => {
				return { loading: false, error: action.payload };
			})
			.addDefaultCase((state) => {
				return { loading: false, error: '', userInfo: {} };
			});
	}
);
export const userDetailsReducer = createReducer({ user: {} }, (builder) => {
	builder
		.addCase(USER_DETAILS_REQUEST, (state) => {
			return { ...state, loading: true };
		})
		.addCase(USER_DETAILS_SUCCESS, (state, action) => {
			return { loading: false, user: action.payload };
		})
		.addCase(USER_DETAILS_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		}) /*
		.addCase(USER_DETAILS_RESET, (state, action) => {
			return { user: {} };
		})*/
		.addDefaultCase((state) => {
			return state;
		});
});
export const userUpdateProfileReducer = createReducer(
	{ userInfo: {} },
	(builder) => {
		builder
			.addCase(USER_UPDATE_PROFILE_REQUEST, (state) => {
				return { loading: true };
			})
			.addCase(USER_UPDATE_PROFILE_SUCCESS, (state, action) => {
				return { loading: false, success: true, userInfo: action.payload };
			})
			.addCase(USER_UPDATE_PROFILE_FAILURE, (state, action) => {
				return { loading: false, error: action.payload };
			})
			.addDefaultCase((state) => {
				return state;
			});
	}
);

export const userListReducer = createReducer({ users: [] }, (builder) => {
	builder
		.addCase(USER_LIST_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(USER_LIST_SUCCESS, (state, action) => {
			return { loading: false, users: action.payload };
		})
		.addCase(USER_LIST_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addCase(USER_LIST_RESET, (state, action) => {
			return { users: [] };
		})
		.addDefaultCase(() => {
			return { loading: false, error: '', users: [] };
		});
});
export const userDeleteReducer = createReducer({}, (builder) => {
	builder
		.addCase(USER_DELETE_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(USER_DELETE_SUCCESS, (state, action) => {
			return { loading: false, success: true };
		})
		.addCase(USER_DELETE_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addDefaultCase(() => {
			return { loading: false, error: '', success: false };
		});
});

export const userUpdateReducer = createReducer({ user: {} }, (builder) => {
	builder
		.addCase(USER_UPDATE_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(USER_UPDATE_SUCCESS, (state, action) => {
			return { loading: false, success: true };
		})
		.addCase(USER_UPDATE_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addCase(USER_UPDATE_RESET, (state, action) => {
			return { user: {} };
		})
		.addDefaultCase(() => {
			return { error: '', user: {} };
		});
});
