import React from 'react';
import '../css/ShippingScreen.css';

const ShippingScreen = ({ formData, setFormData }) => {
	return (
		<div className='shipping-container'>
			<form>
				<div className='address'>
					<label>Building/Apartment</label>
					<input
						type='text'
						placeholder='Enter address'
						value={formData.shipping.address}
						required
						onChange={(e) =>
							setFormData({
								...formData,
								shipping: { ...formData.shipping, address: e.target.value },
							})
						}
					></input>
				</div>
				<div className='region'>
					<label>Region</label>
					<input
						type='text'
						placeholder='Enter Region'
						value={formData.shipping.region}
						required
						onChange={(e) =>
							setFormData({
								...formData,
								shipping: { ...formData.shipping, region: e.target.value },
							})
						}
					></input>
				</div>
				<div className='city'>
					<label>City</label>
					<input
						type='text'
						placeholder='Enter city'
						value={formData.shipping.city}
						required
						onChange={(e) =>
							setFormData({
								...formData,
								shipping: { ...formData.shipping, city: e.target.value },
							})
						}
					></input>
				</div>
				<div className='country'>
					<label>Country</label>
					<input
						type='text'
						placeholder='Enter country'
						value={formData.shipping.country}
						required
						onChange={(e) =>
							setFormData({
								...formData,
								shipping: { ...formData.shipping, country: e.target.value },
							})
						}
					></input>
				</div>
			</form>
		</div>
	);
};

export default ShippingScreen;
