import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listTopProducts } from '../actions/productActions';
import '../css/ProductCarousel.css';

const ProductCarousel = () => {
	const dispatch = useDispatch();
	const productTopRated = useSelector((state) => state.productTopRated);
	const { loading, error, products } = productTopRated;

	useEffect(() => {
		dispatch(listTopProducts());
	}, [dispatch]);

	return loading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'>{error}</Message>
	) : (
		<div className='carousel-container'>
			<div className='carousel'>
				<h2>Featured Products</h2>
				{products.map((product) => (
					<figure className='carousel-slider-item' key={product._id}>
						<a href={`/product/${product._id}`}>
							<div className='carousel-image'>
								<img src={product.image} alt={product.name}></img>
							</div>
						</a>
					</figure>
				))}
			</div>
			<div className='carousel-text'>
				<p>
					Welcome to KaiRobin's Liquor Store!
					<br />
					<br />
					We offer a wide selection of wines from all over the world, as well as
					a variety of liquors and other alcoholic beverages. You can browse all
					of our options for red wine, white wine, whiskeys, gins, spirits,
					vapes, and much more. Find the perfect item for yourself or someone
					special. Whether you're looking for a special occasion cognac, Spirit,
					or just a casual beer, we have something for everyone.
				</p>
			</div>
		</div>
	);
};

export default ProductCarousel;
