import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import Loader from '../components/Loader';
import Message from '../components/Message';
import MPesaButton from '../components/M-PesaButton';
import {
	getOrderDetails,
	payOrder,
	deliverOrder,
	getAuthCode,
	payMpesaOrder,
} from '../actions/orderActions';
import {
	ORDER_PAY_RESET,
	ORDER_DELIVER_RESET,
} from '../constants/orderConstants';
import '../css/OrderScreen.css';

const OrderScreen = () => {
	const history = useNavigate();
	const orderId = useParams().id;
	const [sdkReady, setSdkReady] = useState(false);
	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const orderDetails = useSelector((state) => state.orderDetails);
	const { order, loading, error } = orderDetails;

	const orderPay = useSelector((state) => state.orderPay);
	const { loading: loadingPay, success: successPay } = orderPay;

	const orderDeliver = useSelector((state) => state.orderDeliver);
	const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

	const orderMpesa = useSelector((state) => state.orderMpesa);
	const { mpesa_auth } = orderMpesa;

	useEffect(() => {
		dispatch(getAuthCode());
		setSdkReady(true);
	}, [dispatch]);
	useEffect(() => {
		if (!userInfo) {
			history('/login');
		}
		const addPayPalScript = async () => {
			const { data: clientId } = await axios.get('/api/config/paypal');
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
			script.async = true;
			script.onload = () => {
				setSdkReady(true);
			};
			document.body.appendChild(script);
		};
		dispatch(getOrderDetails(orderId));

		if (!order.isPaid) {
			if (order.paymentMethod === 'PayPal') {
				addPayPalScript();
			}
		}

		if (successPay === true || successDeliver === true) {
			dispatch({ type: ORDER_PAY_RESET });
			dispatch({ type: ORDER_DELIVER_RESET });
			dispatch(getOrderDetails(orderId));
		}
	}, [
		dispatch,
		orderId,
		successPay,
		successDeliver,
		order.isPaid,
		order.paymentMethod,
		history,
		mpesa_auth,
		userInfo,
	]);

	const successPaymentHandler = (paymentResult) => {
		dispatch(payOrder(orderId, paymentResult));
	};
	const deliverHandler = () => {
		dispatch(deliverOrder(order));
	};
	const submitPaymentHandler = async (res_data, config) => {
		dispatch(payMpesaOrder(res_data, config));
	};

	return loading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'>{error}</Message>
	) : (
		<div className='order-screen-container'>
			<h1>Order {order._id}</h1>
			<div className='order-content'>
				<div className='order-user-info'>
					<div className='shipping-info'>
						<h2>Shipping</h2>
						<p>
							<strong>Name: </strong> {order.user.name}
						</p>
						<p>
							<strong>Email: </strong>
							<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
						</p>
						<p>
							<strong>Address:</strong>
							{order.shippingAddress.address}, {order.shippingAddress.city},{' '}
							{order.shippingAddress.region}, {order.shippingAddress.country}
						</p>
						{order.isDelivered ? (
							<Message variant='success'>
								{' '}
								Delivered on {order.deliveredAt}
							</Message>
						) : (
							<Message variant='danger'>Not Delivered</Message>
						)}
					</div>
					<div className='payment-info'>
						<h2>Payment Method</h2>
						<p>
							<strong>Method: </strong>
							{order.paymentMethod}
						</p>
						{order.isPaid ? (
							<Message variant='success'> Paid on {order.paidAt}</Message>
						) : (
							<Message variant='danger'>Not Paid</Message>
						)}
					</div>
					<div className='order-items-info-container'>
						<h2>Order Items</h2>
						{order.orderItems.length === 0 ? (
							<Message>Order is empty</Message>
						) : (
							<div className='order-items-info'>
								{order.orderItems.map((item, index) => (
									<div className='order-item-info' key={index}>
										<div className='order-product-image'>
											<a href={`/product/${item.product}`}>
												<img src={item.image} alt={item.name} />
											</a>
										</div>
										<div className='order-product-name'>
											<a href={`/product/${item.product}`}>{item.name}</a>
										</div>
										<div className='order-product-price'>
											{item.qty} x {item.price} = KSh {item.qty * item.price}
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				<div className='order-cost-info'>
					<h2>Order Summary</h2>
					<div className='order-cost-items'>
						<h3>Items</h3>
						<span>KSh {order.itemsPrice}</span>
					</div>
					<div className='order-cost-shipping'>
						<h3>Shipping</h3>
						<span>KSh {order.shippingPrice}</span>
					</div>
					<div className='order-cost-tax'>
						<h3>Tax</h3>
						<span>KSh {order.taxPrice}</span>
					</div>
					<div className='order-cost-total'>
						<h3>Total</h3>
						<span>KSh {order.totalPrice}</span>
					</div>
					{!order.isPaid && (
						<div className='order-payment-btn'>
							{loadingPay && <Loader />}
							{!sdkReady ? (
								<Loader />
							) : order.paymentMethod === 'PayPal' ? (
								<PayPalButton
									amount={(order.totalPrice * 0.00813473).toFixed(2)}
									onSuccess={successPaymentHandler}
								/>
							) : (
								<MPesaButton
									Amount={order.totalPrice}
									submitPaymentHandler={submitPaymentHandler}
									authData={mpesa_auth}
									orderID={order._id}
								/>
							)}
						</div>
					)}
					{loadingDeliver && <Loader />}
					{userInfo &&
						userInfo.isAdmin &&
						order.isPaid &&
						!order.isDelivered && (
							<div className='order-deliver-btn'>
								<button type='button' onClick={deliverHandler}>
									Mark As Delivered
								</button>
							</div>
						)}
				</div>
			</div>
		</div>
	);
};

export default OrderScreen;
