import React from 'react';
import PropTypes from 'prop-types';
import '../css/Rating.css';

const Rating = ({ value, text, color }) => {
	const maxRating = 5 - Math.round(value);
	const arrValue = [...Array(Math.round(value)).keys()];
	const negValue = [...Array(Number(maxRating)).keys()];

	return (
		<div className='star'>
			{arrValue.map((star, i) => {
				return <i key={i} style={{ color }} className='fas fa-star'></i>;
			})}
			{negValue.map((star, i) => {
				return (
					<i key={i} style={{ color }} className='fa fa-regular fa-star'></i>
				);
			})}
			<span>{text && text}</span>
		</div>
	);
};
Rating.defaultProps = {
	color: 'gold',
};
Rating.propTypes = {
	value: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	color: PropTypes.string,
};
export default Rating;
