import React, { useState } from 'react';
import { Buffer } from 'buffer';
import '../css/M-PesaButton.css';

const MPesaButton = ({ Amount, submitPaymentHandler, authData, orderID }) => {
	const [phoneNumber, setPhoneNumber] = useState('');

	const successPaymentHandler = async (e) => {
		e.preventDefault();

		let phone = phoneNumber;
		if (phoneNumber.startsWith('0') && phoneNumber.length === 10) {
			phone = phoneNumber.replace(/0/, '254');
			setPhoneNumber(phone.toString());
		} else if (!phoneNumber.startsWith('254') && phoneNumber.length === 9) {
			phone = `254${phoneNumber}`;
			setPhoneNumber(`254${phoneNumber}`);
		} else {
			setPhoneNumber(``);
		}

		const date = new Date();
		const timestamp =
			date.getFullYear() +
			('0' + (date.getMonth() + 1)).slice(-2) +
			('0' + date.getDate()).slice(-2) +
			('0' + date.getHours()).slice(-2) +
			('0' + date.getMinutes()).slice(-2) +
			('0' + date.getSeconds()).slice(-2);

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authData.accessToken}`,
			},
		};
		const { shortcode, passkey } = authData;
		const password = new Buffer.from(
			`${shortcode}${passkey}${timestamp}`
		).toString('base64');
		setPhoneNumber(phone);

		const res_data = {
			BusinessShortCode: Number(shortcode),
			Password: password,
			Timestamp: timestamp,
			TransactionType: 'CustomerBuyGoodsOnline',
			Amount: Number(Amount.toFixed(0)),
			PartyA: Number(phone),
			PartyB: Number(shortcode),
			PhoneNumber: Number(phone),
			CallBackURL: `http://www.kls.co.ke/api/orders/${orderID}/pay`,
			AccountReference: phone,
			TransactionDesc: 'Testing if this works.',
		};
		submitPaymentHandler(res_data, config);
	};
	return (
		<div className='mpesa-button-container'>
			<form>
				<input
					required
					type='text'
					maxLength={12}
					placeholder='Phone number'
					onChange={(e) => setPhoneNumber(e.target.value)}
				></input>
				<button type='button' onClick={successPaymentHandler}>
					<img
						src='https://www.logolynx.com/images/logolynx/79/79ec5172e47f04b9680901dd613dbc41.jpeg'
						border='0'
						alt='M-Pesa Acceptance Mark'
					/>
				</button>
			</form>
		</div>
	);
};

export default MPesaButton;
