export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAILURE = 'ORDER_PAY_FAILURE';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_MY_ORDERS_REQUEST = 'ORDER_MY_ORDERS_REQUEST';
export const ORDER_MY_ORDERS_SUCCESS = 'ORDER_MY_ORDERS_SUCCESS';
export const ORDER_MY_ORDERS_FAILURE = 'ORDER_MY_ORDERS_FAILURE';
export const ORDER_MY_ORDERS_RESET = 'ORDER_MY_ORDERS_RESET';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAILURE = 'ORDER_DELIVER_FAILURE';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';

export const ORDER_MPESA_AUTH_REQUEST = 'ORDER_MPESA_AUTH_REQUEST';
export const ORDER_MPESA_AUTH_SUCCESS = 'ORDER_MPESA_AUTH_SUCCESS';
export const ORDER_MPESA_AUTH_FAILURE = 'ORDER_MPESA_AUTH_FAILURE';

export const ORDER_MPESA_PAY_REQUEST = 'ORDER_MPESA_PAY_REQUEST';
export const ORDER_MPESA_PAY_SUCCESS = 'ORDER_MPESA_PAY_SUCCESS';
export const ORDER_MPESA_PAY_FAILURE = 'ORDER_MPESA_PAY_FAILURE';
