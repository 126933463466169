import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import {
	listProducts,
	deleteProduct,
	createProduct,
} from '../actions/productActions';
import {
	PRODUCT_CREATE_RESET,
	PRODUCT_BEVERAGE_SIZE,
	PRODUCT_CATEGORIES,
} from '../constants/productConstants';
import '../css/ProductListScreen.css';

const ProductListScreen = () => {
	const history = useNavigate();

	const [category, setCategory] = useState(PRODUCT_CATEGORIES[0]);
	const [size, setSize] = useState(PRODUCT_BEVERAGE_SIZE[0]);
	const dispatch = useDispatch();

	const productList = useSelector((state) => state.productList);
	const { loading, error, products } = productList;

	const productDelete = useSelector((state) => state.productDelete);
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = productDelete;

	const productCreate = useSelector((state) => state.productCreate);
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		product: createdProduct,
	} = productCreate;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	/***** Get Current Products *****/
	const [currentPage, setCurrentPage] = useState(1);

	const filterProduct = (product) => {
		if (category === 'All' && size === 'All') {
			return product;
		} else if (category === 'All' && size !== 'All') {
			return product.size === size;
		} else if (category !== 'All' && size === 'All') {
			return product.category === category;
		} else {
			return product.category === category && product.size === size;
		}
	};

	const [productsPerPage] = useState(10);
	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = products
		.filter(filterProduct)
		.slice(indexOfFirstProduct, indexOfLastProduct);

	useEffect(() => {
		dispatch({ type: PRODUCT_CREATE_RESET });

		if (!userInfo.isAdmin) {
			history('/login');
		}
		if (successCreate) {
			history(`/admin/product/${createdProduct._id}/edit`);
		} else {
			dispatch(listProducts(''));
		}
	}, [
		dispatch,
		history,
		userInfo,
		userInfo.isAdmin,
		successDelete,
		successCreate,
		createdProduct,
	]);
	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteProduct(id));
		}
	};
	const createProductHandler = () => {
		dispatch(createProduct());
	};
	const paginate = (pageNumber) =>
		setCurrentPage(pageNumber === 0 ? 1 : pageNumber);
	return (
		<>
			{loadingDelete && <Loader />}
			{errorDelete && <Message variant='danger'>{errorDelete}</Message>}
			{loadingCreate && <Loader />}
			{errorCreate && <Message variant='danger'>{errorCreate}</Message>}
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<div className='product-table-container'>
					<h1>Products</h1>

					<div className='create-product-btn'>
						<button onClick={createProductHandler}>
							<i className='fas fa-plus'></i> Create Product
						</button>
					</div>
					<div className='main-filter'>
						<div className='input-field'>
							<label>
								<p>Choose Category: </p>
							</label>
							<select
								value={category}
								onChange={(e) => {
									setCategory(e.target.value);
									setCurrentPage(1);
								}}
							>
								{PRODUCT_CATEGORIES.map((x) => (
									<option key={x} value={x} defaultValue={category}>
										{x}
									</option>
								))}{' '}
							</select>
						</div>
						<div className='input-field'>
							<label>
								<p>Choose Size: </p>
							</label>
							<select
								value={size}
								onChange={(e) => {
									setSize(e.target.value);
									setCurrentPage(1);
								}}
							>
								{PRODUCT_BEVERAGE_SIZE.map((x) => (
									<option key={x} value={x} defaultValue={size}>
										{x}
									</option>
								))}{' '}
							</select>
						</div>
					</div>
					<table className='product-table'>
						<thead>
							<tr>
								<th>NAME</th>
								<th>SIZE</th>
								<th>PRICE</th>
								<th>CATEGORY</th>
								<th>COUNT IN STOCK</th>
								<th>DESCRIPTION</th>
								<th>IMAGE</th>
								<th>ID</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{currentProducts.map((product) => (
								<tr key={product._id}>
									<td>{product.name}</td>
									<td>{product.size}</td>
									<td>KSh {product.price}</td>
									<td>{product.category}</td>
									<td>{product.countInStock}</td>
									<td>{product.description}</td>
									<td>{product.image}</td>
									<td>{product._id}</td>
									<td>
										<div className='edit-product-btn-containter'>
											<a href={`/admin/product/${product._id}/edit`}>
												<button>
													<i className='fas fa-edit'></i>
												</button>
											</a>
											<button
												className='product-trash-btn'
												onClick={() => deleteHandler(product._id)}
											>
												<i className='fas fa-trash'></i>
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<Paginate
						productsPerPage={productsPerPage}
						totalProducts={products.filter(filterProduct).length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			)}
		</>
	);
};

export default ProductListScreen;
