import { createReducer } from '@reduxjs/toolkit';
import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_SAVE_SHIPPING_ADDRESS,
	CART_SAVE_PAYMENT_METHOD,
	CART_CALUCULATE_PRICE,
} from '../constants/cartConstants';

export const cartReducer = createReducer(
	{ cartItems: [], shippingAddress: {} },
	(builder) => {
		builder
			.addCase(CART_ADD_ITEM, (state, action) => {
				const item = action.payload;
				const existItem = state.cartItems.find(
					(x) => x.product === item.product
				);

				if (existItem) {
					return {
						...state,
						cartItems: state.cartItems.map((x) =>
							x.product === existItem.product ? item : x
						),
					};
				} else {
					return {
						...state,
						cartItems: [...state.cartItems, item],
					};
				}
			})
			.addCase(CART_REMOVE_ITEM, (state, action) => {
				return {
					...state,
					cartItems: state.cartItems.filter(
						(x) => x.product !== action.payload
					),
				};
			})
			.addCase(CART_SAVE_SHIPPING_ADDRESS, (state, action) => {
				return {
					...state,
					shippingAddress: action.payload,
				};
			})
			.addCase(CART_SAVE_PAYMENT_METHOD, (state, action) => {
				return {
					...state,
					paymentMethod: action.payload,
				};
			})
			.addCase(CART_CALUCULATE_PRICE, (state, action) => {
				const SHIPPING_COST = 100;
				const DEFAULT_SHIPPING_COST = 100;
				const SHIPPING_LIMIT = 1800;
				const TAX_RATE = 0.05;

				const itemsPrice = state.cartItems.reduce(
					(acc, item) => acc + item.price * item.qty,
					0
				);
				const shippingPrice =
					itemsPrice > SHIPPING_LIMIT ? SHIPPING_COST : DEFAULT_SHIPPING_COST;
				const taxPrice = Math.round(TAX_RATE * itemsPrice);
				const totalPrice =
					Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice);

				return {
					...state,
					itemsPrice,
					shippingPrice,
					taxPrice,
					totalPrice,
				};
			})
			.addDefaultCase((state) => {
				return state;
			});
	}
);
