import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Message from '../components/Message';
import { calculatePrice } from '../actions/cartActions';
import { createOrder } from '../actions/orderActions';
import '../css/PlaceOrderScreen.css';

const PlaceOrderScreen = ({ history, formData }) => {
	const dispatch = useDispatch();
	const cart = useSelector((state) => state.cart);
	const { itemsPrice, shippingPrice, taxPrice, totalPrice } = cart;

	const orderCreate = useSelector((state) => state.orderCreate);
	const { order, success, error } = orderCreate;

	useEffect(() => {
		dispatch(calculatePrice());
		if (success) {
			history(`/order/${order._id}`);
		}
	}, [dispatch, history, success, order, formData]);

	const placeOrderHandler = () => {
		dispatch(
			createOrder({
				orderItems: cart.cartItems,
				shippingAddress: formData.shipping,
				paymentMethod: formData.paymentMethod,
				itemsPrice: cart.itemsPrice,
				shippingPrice: cart.shippingPrice,
				taxPrice: cart.taxPrice,
				totalPrice: cart.totalPrice,
			})
		);
	};
	return (
		<div className='place-order-container'>
			<div className='order-details'>
				<div className='order-shipping'>
					<h2>Shipping</h2>
					<p>
						<strong>Address:</strong>
						{formData.shipping.address}, {formData.shipping.city},{' '}
						{formData.shipping.region}, {formData.shipping.country}
					</p>
				</div>
				<div className='order-payment-method'>
					<h2>Payment Method</h2>
					<strong>Method: </strong>
					{formData.paymentMethod}
				</div>
				<div className='order-items-container'>
					<h2>Order Items</h2>
					{cart.cartItems.length === 0 ? (
						<Message>Your cart is empty</Message>
					) : (
						<div className='order-items'>
							{cart.cartItems.map((item, index) => (
								<div className='order-item' key={index}>
									<div className='order-product-image'>
										<a href={`/product/${item.product}`}>
											<img src={item.image} alt={item.name} />
										</a>
									</div>
									<div className='order-product-name'>
										<a href={`/product/${item.product}`}>{item.name}</a>
									</div>
									<div className='order-product-price'>
										{item.qty} x {item.price} = KSh {item.qty * item.price}
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
			<div className='order-cost'>
				<div className='order-cost-summary'>
					<h2>Order Summary</h2>
				</div>
				<div className='order-cost-items'>
					<div>
						<strong>Items:</strong>
					</div>
					<div>KSh {itemsPrice}</div>
				</div>
				<div className='order-cost-shipping'>
					<div>
						<strong>Shipping:</strong>
					</div>
					<div>KSh {shippingPrice}</div>
				</div>
				<div className='order-cost-tax'>
					<div>
						<strong>Tax:</strong>
					</div>
					<div>KSh {taxPrice}</div>
				</div>
				<div className='order-cost-total'>
					<div>
						<strong>Total:</strong>
					</div>
					<div className='total-price'>
						<span>KSh {totalPrice}</span>
					</div>
				</div>
				<div>{error && <Message variant='danger'>{error}</Message>}</div>
				<div className='place-order-btn'>
					<button
						type='button'
						disabled={
							cart.cartItems.length === 0 ||
							!formData.shipping ||
							!formData.paymentMethod
						}
						onClick={placeOrderHandler}
					>
						Place Order
					</button>
				</div>
			</div>
		</div>
	);
};

export default PlaceOrderScreen;
