import { createReducer } from '@reduxjs/toolkit';
import {
	PRODUCT_LIST_REQUEST,
	PRODUCT_LIST_SUCCESS,
	PRODUCT_LIST_FAILURE,
	PRODUCT_DETAILS_REQUEST,
	PRODUCT_DETAILS_SUCCESS,
	PRODUCT_DETAILS_FAILURE,
	PRODUCT_DELETE_REQUEST,
	PRODUCT_DELETE_SUCCESS,
	PRODUCT_DELETE_FAILURE,
	PRODUCT_CREATE_REQUEST,
	PRODUCT_CREATE_SUCCESS,
	PRODUCT_CREATE_FAILURE,
	PRODUCT_CREATE_RESET,
	PRODUCT_UPDATE_REQUEST,
	PRODUCT_UPDATE_SUCCESS,
	PRODUCT_UPDATE_FAILURE,
	PRODUCT_UPDATE_RESET,
	PRODUCT_CREATE_REVIEW_REQUEST,
	PRODUCT_CREATE_REVIEW_SUCCESS,
	PRODUCT_CREATE_REVIEW_FAILURE,
	PRODUCT_CREATE_REVIEW_RESET,
	PRODUCT_TOP_REQUEST,
	PRODUCT_TOP_SUCCESS,
	PRODUCT_TOP_FAILURE,
} from '../constants/productConstants';

export const productListReducer = createReducer({ products: [] }, (builder) => {
	builder
		.addCase(PRODUCT_LIST_REQUEST, (state) => {
			return { loading: true, ...state };
		})
		.addCase(PRODUCT_LIST_SUCCESS, (state, action) => {
			return {
				loading: false,
				products: action.payload.products,
			};
		})
		.addCase(PRODUCT_LIST_FAILURE, (action) => {
			return { loading: false, error: action.payload };
		})
		.addDefaultCase((state, action) => {
			return {
				error: '',
				products: [],
			};
		});
});

export const productDetailsReducer = createReducer(
	{ product: { reviews: [], rating: 0 } },
	(builder) => {
		builder
			.addCase(PRODUCT_DETAILS_REQUEST, (state) => {
				return { loading: true, ...state };
			})
			.addCase(PRODUCT_DETAILS_SUCCESS, (state, action) => {
				return { loading: false, product: action.payload };
			})
			.addCase(PRODUCT_DETAILS_FAILURE, (state, action) => {
				return { loading: false, error: action.payload };
			})
			.addDefaultCase(() => {
				return {
					error: '',
					product: { reviews: [], rating: 0 },
				};
			});
	}
);
export const productDeleteReducer = createReducer({}, (builder) => {
	builder
		.addCase(PRODUCT_DELETE_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(PRODUCT_DELETE_SUCCESS, (state, action) => {
			return { loading: false, success: true };
		})
		.addCase(PRODUCT_DELETE_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addDefaultCase(() => {
			return {
				error: '',
				success: false,
			};
		});
});
export const productCreateReducer = createReducer({}, (builder) => {
	builder
		.addCase(PRODUCT_CREATE_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(PRODUCT_CREATE_SUCCESS, (state, action) => {
			return { loading: false, success: true, product: action.payload };
		})
		.addCase(PRODUCT_CREATE_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addCase(PRODUCT_CREATE_RESET, (state, action) => {
			return {};
		})
		.addDefaultCase(() => {
			return {
				error: '',
				success: false,
			};
		});
});
export const productUpdateReducer = createReducer(
	{ product: {} },
	(builder) => {
		builder
			.addCase(PRODUCT_UPDATE_REQUEST, (state) => {
				return { loading: true };
			})
			.addCase(PRODUCT_UPDATE_SUCCESS, (state, action) => {
				return { loading: false, success: true, product: action.payload };
			})
			.addCase(PRODUCT_UPDATE_FAILURE, (state, action) => {
				return { loading: false, error: action.payload };
			})
			.addCase(PRODUCT_UPDATE_RESET, (state, action) => {
				return { product: {} };
			})
			.addDefaultCase(() => {
				return { error: '', success: false, product: {} };
			});
	}
);

export const productCreateReviewReducer = createReducer({}, (builder) => {
	builder
		.addCase(PRODUCT_CREATE_REVIEW_REQUEST, (state) => {
			return { ...state, loading: true };
		})
		.addCase(PRODUCT_CREATE_REVIEW_SUCCESS, (state, action) => {
			return { loading: false, success: true };
		})
		.addCase(PRODUCT_CREATE_REVIEW_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addCase(PRODUCT_CREATE_REVIEW_RESET, (state, action) => {
			return {};
		})
		.addDefaultCase((state) => {
			return state;
		});
});

export const productTopRatedReducer = createReducer(
	{ products: [] },
	(builder) => {
		builder
			.addCase(PRODUCT_TOP_REQUEST, (state) => {
				return { loading: true };
			})
			.addCase(PRODUCT_TOP_SUCCESS, (state, action) => {
				return { loading: false, products: action.payload };
			})
			.addCase(PRODUCT_TOP_FAILURE, (action) => {
				return { loading: false, error: action.payload };
			})
			.addDefaultCase((state) => {
				return state;
			});
	}
);
/*
export const productListReducer_old_redux = (state = { products: [] }, action) => {
	switch (action.type) {
		case 'PRODUCT_LIST_REQUEST':
			return { loading: true, products: [] };
		case 'PRODUCT_LIST_SUCCESS':
			return { loading: false, products: action.payload };
		case 'PRODUCT_LIST_FAILURE':
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
*/
