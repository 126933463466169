import React from 'react';
import '../css/PaymentScreen.css';

const PaymentScreen = ({ formData, setFormData }) => {
	return (
		<div className='payment-container'>
			<form>
				<div className='payment-method'>
					<div className='mpesa'>
						<input
							type='radio'
							label='M-Pesa'
							id='M-Pesa'
							name='paymentMethod'
							value='M-Pesa'
							onLoad={(e) =>
								setFormData({ ...formData, paymentMethod: e.target.value })
							}
							onChange={(e) =>
								setFormData({ ...formData, paymentMethod: e.target.value })
							}
						/>
						<img
							src='https://www.logolynx.com/images/logolynx/79/79ec5172e47f04b9680901dd613dbc41.jpeg'
							border='0'
							alt='M-Pesa Acceptance Mark'
						/>
					</div>
					<div className='paypal'>
						<input
							type='radio'
							label='Paypal or Credit Card'
							id='PayPal'
							name='paymentMethod'
							value='PayPal'
							onChange={(e) =>
								setFormData({ ...formData, paymentMethod: e.target.value })
							}
						/>
						<img
							src='https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg'
							border='0'
							alt='PayPal Acceptance Mark'
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PaymentScreen;
