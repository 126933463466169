import React from 'react';
import Rating from './Rating';
import '../css/Product.css';

const Product = ({ product }) => {
	return (
		<div className='card'>
			<a href={`/product/${product._id}`}>
				<div className='card-image-box'>
					<img src={product.image} alt={product.name} />
				</div>
				<div className='card-content-box'>
					<div className='card-details'>
						<div className='name'>
							<h3>{product.name}</h3>
						</div>
						<div className='size'>
							<p>{product.size}</p>
						</div>
						<div className='rating'>
							<Rating
								value={Number(product.rating)}
								text={`${product.numReviews} reviews`}
							/>
						</div>
						<div className='price'>
							<h3>KSh {product.price}</h3>
						</div>
					</div>
				</div>
			</a>
		</div>
	);
};

export default Product;
