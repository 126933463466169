import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import { listMyOrders } from '../actions/orderActions';
import '../css/ProfileScreen.css';

const ProfileScreen = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const sortTypes = ['Sort By...', 'createdAt', 'totalPrice'];

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState(null);

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, error, user } = userDetails;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
	const { success } = userUpdateProfile;

	const orderMyList = useSelector((state) => state.orderMyList);
	const { loading: loadingOrders, error: errorOrders, orders } = orderMyList;

	const [sortedOrders, setSortedOrders] = useState([]);
	const [sortType, setSortType] = useState('');

	useEffect(() => {
		const sortArray = (type) => {
			const types = {
				createdAt: 'createdAt',
				totalPrice: 'totalPrice',
			};
			const sortProperty = types[type];
			const sorted = [].concat(orders).sort((a, b) => {
				if (sortProperty === 'createdAt') {
					return new Date(b[sortProperty]) - new Date(a[sortProperty]);
				}
				return b[sortProperty] - a[sortProperty];
			});
			setSortedOrders(sorted);
		};
		if (!userInfo) {
			history('/login');
		} else {
			if (!user.name) {
				dispatch(getUserDetails('profile'));
				dispatch(listMyOrders());
			} else {
				setName(user.name);
				setEmail(user.email);
			}
		}
		if (orders) {
			sortArray(sortType);
		}
	}, [dispatch, history, userInfo, user, sortType]);

	const submitHandler = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Passwords do not match');
		} else {
			dispatch(updateUserProfile({ id: user._id, name, email, password }));
		}
	};
	return (
		<div className='profile-container'>
			<div className='profile-form-container'>
				<h2>User Profile</h2>
				{message && <Message variant='danger'>{message}</Message>}
				{error && <Message variant='danger'>{error}</Message>}
				{success && <Message variant='success'>Profile Updated</Message>}
				{loading && <Loader />}
				<form onSubmit={submitHandler}>
					<div className='input-field'>
						<label>Name</label>
						<input
							type='name'
							placeholder='Enter name'
							value={name}
							onChange={(e) => setName(e.target.value)}
						></input>
					</div>
					<div className='input-field'>
						<label>Email Address</label>
						<input
							type='email'
							placeholder='Enter email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></input>
					</div>
					<div className='input-field'>
						<label>Password</label>
						<input
							type='password'
							placeholder='Enter password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						></input>
					</div>
					<div className='input-field'>
						<label>Confirm Password</label>
						<input
							type='password'
							placeholder='Confirm password'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						></input>
					</div>
					<button type='submit'>Update</button>
				</form>
			</div>
			<>
				{loadingOrders ? (
					<Loader />
				) : errorOrders ? (
					<Message variant='danger'>{errorOrders}</Message>
				) : orders ? (
					<div className='profile-table-container'>
						<h2> My Orders</h2>
						<div className='input-field'>
							<select onChange={(e) => setSortType(e.target.value)}>
								{sortTypes.map((x) => (
									<option key={x} value={x} defaultValue={sortType}>
										{x}
									</option>
								))}{' '}
							</select>
						</div>
						<table className='profile-table'>
							<thead>
								<tr>
									<th>DATE</th>
									<th>TOTAL</th>
									<th>PAID</th>
									<th>DELIVERED</th>
									<th>ID</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{sortedOrders.map((order) => (
									<tr key={order._id}>
										<td>{order.createdAt.substring(0, 10)}</td>
										<td>KSh {order.totalPrice}</td>
										<td>
											{order.isPaid ? (
												order.paidAt.substring(0, 10)
											) : (
												<i
													className='fas fa-times'
													style={{ color: 'red' }}
												></i>
											)}
										</td>
										<td>
											{order.isDelivered ? (
												order.deliveredAt.substring(0, 10)
											) : (
												<i
													className='fas fa-times'
													style={{ color: 'red' }}
												></i>
											)}
										</td>
										<td>{order._id}</td>
										<td>
											<a href={`/order/${order._id}`}>
												<div className='order-details-btn'>
													<button variant='primary'>Details</button>
												</div>
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<Message variant='danger'>NO ORDERS TO LIST</Message>
				)}
			</>
		</div>
	);
};

export default ProfileScreen;
