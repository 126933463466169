import { createReducer } from '@reduxjs/toolkit';
import {
	ORDER_CREATE_FAILURE,
	ORDER_CREATE_REQUEST,
	ORDER_CREATE_SUCCESS,
	ORDER_DETAILS_FAILURE,
	ORDER_DETAILS_REQUEST,
	ORDER_DETAILS_SUCCESS,
	ORDER_MY_ORDERS_FAILURE,
	ORDER_MY_ORDERS_REQUEST,
	ORDER_MY_ORDERS_SUCCESS,
	ORDER_PAY_FAILURE,
	ORDER_PAY_REQUEST,
	ORDER_PAY_RESET,
	ORDER_PAY_SUCCESS,
	ORDER_LIST_REQUEST,
	ORDER_LIST_SUCCESS,
	ORDER_LIST_FAILURE,
	ORDER_DELIVER_RESET,
	ORDER_DELIVER_FAILURE,
	ORDER_DELIVER_SUCCESS,
	ORDER_DELIVER_REQUEST,
	ORDER_MPESA_AUTH_REQUEST,
	ORDER_MPESA_AUTH_SUCCESS,
	ORDER_MPESA_AUTH_FAILURE,
} from '../constants/orderConstants';

export const orderCreateReducer = createReducer(
	{ cartItems: [] },
	(builder) => {
		builder
			.addCase(ORDER_CREATE_REQUEST, (state, action) => {
				return { loading: true };
			})
			.addCase(ORDER_CREATE_SUCCESS, (state, action) => {
				return { loading: false, success: true, order: action.payload };
			})
			.addCase(ORDER_CREATE_FAILURE, (state, action) => {
				return { loading: false, error: action.payload };
			})
			.addDefaultCase((state) => {
				return state;
			});
	}
);
export const orderDetailsReducer = createReducer(
	{
		orderItems: [],
		shippingAddress: {},
	},
	(builder) => {
		builder
			.addCase(ORDER_DETAILS_REQUEST, (state) => {
				return { ...state, loading: true };
			})
			.addCase(ORDER_DETAILS_SUCCESS, (state, action) => {
				return { loading: false, order: action.payload };
			})
			.addCase(ORDER_DETAILS_FAILURE, (state, action) => {
				return { loading: false, error: action.payload };
			})
			.addDefaultCase((state) => {
				return { loading: true, error: '', order: {} };
			});
	}
);
export const orderMpesaReducer = createReducer({}, (builder) => {
	builder
		.addCase(ORDER_MPESA_AUTH_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(ORDER_MPESA_AUTH_SUCCESS, (state, action) => {
			return { loading: false, mpesa_auth: action.payload };
		})
		.addCase(ORDER_MPESA_AUTH_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addDefaultCase((state) => {
			return state;
		});
});
export const orderPayReducer = createReducer({}, (builder) => {
	builder
		.addCase(ORDER_PAY_REQUEST, (state) => {
			return { ...state, loading: true };
		})
		.addCase(ORDER_PAY_SUCCESS, (state, action) => {
			return { loading: false, success: true };
		})
		.addCase(ORDER_PAY_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addCase(ORDER_PAY_RESET, (state, action) => {
			return {};
		})
		.addDefaultCase((state) => {
			return state;
		});
});
export const orderListMyReducer = createReducer({ orders: [] }, (builder) => {
	builder
		.addCase(ORDER_MY_ORDERS_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(ORDER_MY_ORDERS_SUCCESS, (state, action) => {
			return { loading: false, orders: action.payload };
		})
		.addCase(ORDER_MY_ORDERS_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addDefaultCase((state) => {
			return state;
		});
});
export const orderListReducer = createReducer({ orders: [] }, (builder) => {
	builder
		.addCase(ORDER_LIST_REQUEST, (state) => {
			return { loading: true };
		})
		.addCase(ORDER_LIST_SUCCESS, (state, action) => {
			return { loading: false, orders: action.payload };
		})
		.addCase(ORDER_LIST_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addDefaultCase((state) => {
			return state;
		});
});
export const orderDeliverReducer = createReducer({}, (builder) => {
	builder
		.addCase(ORDER_DELIVER_REQUEST, (state) => {
			return { ...state, loading: true };
		})
		.addCase(ORDER_DELIVER_SUCCESS, (state, action) => {
			return { loading: false, success: true };
		})
		.addCase(ORDER_DELIVER_FAILURE, (state, action) => {
			return { loading: false, error: action.payload };
		})
		.addCase(ORDER_DELIVER_RESET, (state, action) => {
			return {};
		})
		.addDefaultCase((state) => {
			return state;
		});
});
