import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listTopProducts } from '../actions/productActions';
import '../css/ContactUsScreen.css';

// TODO: Finish this screen
const ContactUsScreen = () => {
	const dispatch = useDispatch();
	const productTopRated = useSelector((state) => state.productTopRated);
	const { loading, error, products } = productTopRated;

	useEffect(() => {
		dispatch(listTopProducts());
	}, [dispatch]);

	return (
		<div className='contactus-screen-container'>
			<div className='contact-info'>
				<div className='contact-info-left'>
					<h1>Contact Info</h1>
					<h2>Phone</h2>
					<p>0746208862</p>
					<h2>Address</h2>
					<p>Soko Safi Mall, Kilimani, Nairobi </p>
					<h2>Social Media</h2>
					<ul>
						<li>
							<a href='https://instagram.com/kairobinsliquorstore'>
								<i className='fab fa-instagram'></i>
								<span> kairobinsliquorstore</span>
							</a>
						</li>
						<li>
							<a href='/'>
								<i className='fab fa-facebook'></i>
								<span> kairobinsliquorstore</span>
							</a>
						</li>
						<li>
							<a href='/'>
								<i className='fab fa-twitter'></i>
								<span> kairobinsliquorstore</span>
							</a>
						</li>
						<li>
							<a href='/'>
								<i className='fab fa-whatsapp'></i>
								<span> 0746208862</span>
							</a>
						</li>
					</ul>
				</div>
				<div className='contact-info-right'>
					{loading ? (
						<Loader />
					) : error ? (
						<Message variant='danger'>{error}</Message>
					) : (
						<div className='carousel'>
							<h2>Featured Products</h2>
							{products.map((product) => (
								<figure className='carousel-slider-item' key={product._id}>
									<a href={`/product/${product._id}`}>
										<div className='carousel-image'>
											<img src={product.image} alt={product.name}></img>
										</div>
									</a>
								</figure>
							))}
						</div>
					)}
				</div>
			</div>
			<div className='contact-map'>
				<iframe
					title="KaiRobin's Liquor Store"
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d249.29941615167928!2d36.78275217941441!3d-1.3004555834835454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f114e023f57bb%3A0x2e9682bb45b1c9d2!2sSoko%20Safi!5e0!3m2!1sen!2ske!4v1671208504012!5m2!1sen!2ske'
					allowfullscreen=''
					width='100%'
					height='100%'
					loading='lazy'
					referrerpolicy='no-referrer-when-downgrade'
				></iframe>
			</div>
		</div>
	);
};

export default ContactUsScreen;
