import React, { useState, useEffect } from 'react';
import '../css/Message.css';

const Message = ({ variant, children }) => {
	const [color, setColor] = useState('rgb(40, 40, 185)');
	const [background, setBackground] = useState('rgba(163, 229, 255, 0.7)');

	useEffect(() => {
		if (variant === 'danger') {
			setBackground('rgba(255, 163, 194, 0.7)');
			setColor('rgb(255, 0, 0)');
		} else if (variant === 'success') {
			setBackground('rgba(0, 255, 34, 0.7)');
			setColor('rgb(2, 95, 10)');
		}
	}, [variant]);

	return (
		<div className='message-container' style={{ background, color }}>
			<strong>{children}</strong>
		</div>
	);
};

export default Message;
