import React from 'react';
import '../css/Footer.css';

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='footer-items'>
				<div className='footer-item'>
					<h2>More Links</h2>
					<ul>
						<li>
							<a href='/cart'>Cart</a>
						</li>
						<li>
							<a href='/aboutus'>About Us</a>
						</li>
						<li>
							<a href='/contactus'>Contact Us</a>
						</li>
					</ul>
				</div>
				<div className='footer-item'>
					<h2>Social Media</h2>
					<ul>
						<li>
							<a href='https://instagram.com/kairobinsliquorstore'>
								<i className='fab fa-instagram'></i>
								<span> kairobinsliquorstore</span>
							</a>
						</li>
						<li>
							<a href='/'>
								<i className='fab fa-facebook'></i>
								<span> kairobinsliquorstore</span>
							</a>
						</li>
						<li>
							<a href='/'>
								<i className='fab fa-twitter'></i>
								<span> kairobinsliquorstore</span>
							</a>
						</li>
						<li>
							<a href='/'>
								<i className='fab fa-whatsapp'></i>
								<span> 0746208862</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className='footer-item-copyright'>
				<span>Copyright &copy; KaiRobin's Liquor Store</span>
				<span>
					Designed by <a href='https://safires.org'>Coding 4 Da Culture</a>
				</span>
			</div>
		</footer>
	);
};

export default Footer;
