import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listUsers, deleteUser } from '../actions/userActions';
import '../css/UserListScreen.css';

const UserListScreen = () => {
	const history = useNavigate();
	const dispatch = useDispatch();

	const userList = useSelector((state) => state.userList);
	const { loading, error, users } = userList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDelete = useSelector((state) => state.userDelete);
	const { success: successDelete } = userDelete;

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listUsers());
		} else {
			history('/login');
		}
	}, [dispatch, history, userInfo, userInfo.isAdmin, successDelete]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id));
		}
	};
	return (
		<>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<div className='user-table-container'>
					<h1>Users</h1>
					<table className='user-table'>
						<thead>
							<tr>
								<th>NAME</th>
								<th>EMAIL</th>
								<th>ADMIN</th>
								<th>ID</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{users.map((user) => (
								<tr key={user._id}>
									<td>{user.name}</td>
									<td>
										<a href={`mailto:${user.email}`}>{user.email}</a>
									</td>
									<td>
										{user.isAdmin ? (
											<i
												className='fas fa-check'
												style={{ color: 'green' }}
											></i>
										) : (
											<i className='fas fa-times' style={{ color: 'red' }}></i>
										)}
									</td>
									<td>{user._id}</td>
									<td>
										<div className='edit-btn-containter'>
											<a href={`/admin/user/${user._id}/edit`}>
												<button variant='primary'>
													<i className='fas fa-edit'></i>
												</button>
											</a>
											<button
												variant='danger'
												disabled={user.isAdmin === true}
												onClick={() => deleteHandler(user._id)}
											>
												<i className='fas fa-trash'></i>
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};

export default UserListScreen;
