import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LoginScreen from './LoginScreen';
import PaymentScreen from './PaymentScreen';
import PlaceOrderScreen from './PlaceOrderScreen';
import ShippingScreen from './ShippingScreen';
import { saveShippingAddress } from '../actions/cartActions';
import { savePaymentMethod } from '../actions/cartActions';

import '../css/OrderFormScreen.css';

const OrderFormScreen = () => {
	const location = useLocation();
	const history = useNavigate();
	const [page, setPage] = useState(0);

	const cart = useSelector((state) => state.cart);
	const { shippingAddress } = cart;

	const [address, setAddress] = useState(shippingAddress.address);
	const [city, setCity] = useState(shippingAddress.city);
	const [region, setRegion] = useState(shippingAddress.region);
	const [country, setCountry] = useState(shippingAddress.country);

	const [formData, setFormData] = useState({
		shipping: {
			address: address,
			city: city,
			region: region,
			country: country,
		},
		paymentMethod: '',
	});

	const formTitles = ['Login', 'Shipping', 'Payment Method', 'Place Order'];
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const dispatch = useDispatch();

	const nextBtn = () => {
		if (page === 1) {
			dispatch(
				saveShippingAddress({
					address: formData.shipping.address,
					city: formData.shipping.city,
					region: formData.shipping.region,
					country: formData.shipping.country,
				})
			);
		} else if (page === 2) {
			dispatch(savePaymentMethod({ paymentMethod: formData.paymentMethod }));
		}
		setPage(page + 1);
	};
	const prevBtn = () => {
		setPage(page - 1);
	};
	const pageDisplay = () => {
		if (page === 0 && !userInfo) {
			return <LoginScreen location={location} history={history} />;
		} else if (page === 0 && userInfo) {
			setPage(1);
		} else if (page === 1) {
			return <ShippingScreen formData={formData} setFormData={setFormData} />;
		} else if (page === 2) {
			return <PaymentScreen formData={formData} setFormData={setFormData} />;
		} else if (page === 3) {
			return (
				<PlaceOrderScreen
					history={history}
					formData={formData}
					setFormData={setFormData}
				/>
			);
		}
	};
	return (
		<div className='order-form'>
			<div className='form-progress-bar'>
				<div
					style={{
						width:
							page === 0
								? '25%'
								: page === 1
								? '50%'
								: page === 2
								? '75%'
								: '100%',
					}}
				></div>
			</div>
			<div className='form-container'>
				<div className='form-header'>
					<h1>{formTitles[page]}</h1>
				</div>
				<div className='form-body'>{pageDisplay()}</div>
				<div className='form-footer'>
					<button className='prev' disabled={page === 0} onClick={prevBtn}>
						Prev
					</button>
					<button
						className='next'
						style={{ display: page === formTitles.length - 1 ? 'none' : '' }}
						disabled={page === formTitles.length - 1 || !userInfo}
						onClick={nextBtn}
						hidden={!userInfo}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default OrderFormScreen;
