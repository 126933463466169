import React from 'react';
import '../css/Loader.css';
const Loader = () => {
	return (
		<div className='loader-container'>
			<div className='keg'>
				<span className='handle'></span>
				<span className='pipe'></span>
			</div>
			<div className='glass'>
				<span className='beer'></span>
			</div>
		</div>
	);
};
export default Loader;
