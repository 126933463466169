import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Product from '../components/Product';
import ProductCarousel from '../components/ProductCarousel';
import { listProducts } from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import '../css/HomeScreen.css';
import {
	PRODUCT_BEVERAGE_SIZE,
	PRODUCT_CATEGORIES,
} from '../constants/productConstants';

const HomeScreen = () => {
	const keyword = useParams().keyword;
	const dispatch = useDispatch();

	const productList = useSelector((state) => state.productList);
	const { loading, error, products, page, pages } = productList;
	const [category, setCategory] = useState(PRODUCT_CATEGORIES[0]);
	const [size, setSize] = useState(PRODUCT_BEVERAGE_SIZE[0]);

	useEffect(() => {
		dispatch(listProducts(keyword));
	}, [dispatch, keyword]);

	/***** Get Current Products *****/
	const [currentPage, setCurrentPage] = useState(1);
	const filterProduct = (product) => {
		if (category === 'All' && size === 'All') {
			return product;
		} else if (category === 'All' && size !== 'All') {
			return product.size === size;
		}
		if (category !== 'All' && size === 'All') {
			return product.category === category;
		} else {
			return product.category === category && product.size === size;
		}
	};
	const [productsPerPage] = useState(10);
	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = products
		.filter(filterProduct)
		.slice(indexOfFirstProduct, indexOfLastProduct);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className='main'>
			{!keyword && <ProductCarousel />}
			<div className='description'>
				<strong>
					Browse our selection and find the perfect drink for your next party or
					any event... Live Free!
				</strong>
			</div>
			<div className='main-filter'>
				<div className='input-field'>
					<label>
						<p>Choose Category: </p>
					</label>
					<select
						value={category}
						onChange={(e) => {
							setCategory(e.target.value);
							setCurrentPage(1);
						}}
					>
						{PRODUCT_CATEGORIES.map((x) => (
							<option key={x} value={x} defaultValue={category}>
								{x}
							</option>
						))}{' '}
					</select>
				</div>
				<div className='input-field'>
					<label>
						<p>Choose Size: </p>
					</label>
					<select
						value={size}
						onChange={(e) => {
							setSize(e.target.value);
							setCurrentPage(1);
						}}
					>
						{PRODUCT_BEVERAGE_SIZE.map((x) => (
							<option key={x} value={x} defaultValue={size}>
								{x}
							</option>
						))}{' '}
					</select>
				</div>
			</div>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<div className='cards'>
						{currentProducts.map((product) => (
							<div key={product._id}>
								<Product product={product} />
							</div>
						))}
					</div>
					<Paginate
						productsPerPage={productsPerPage}
						totalProducts={products.filter(filterProduct).length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</>
			)}
		</div>
	);
};

export default HomeScreen;
