import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import OrderListScreen from './screens/OrderListScreen';
import OrderFormScreen from './screens/OrderFormScreen';
import AboutUsScreen from './screens/AboutUsScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import './index.css';

function App() {
	return (
		<>
			<Header />
			<Routes>
				<Route path='/' exact element={<HomeScreen />} />
				<Route path='/login' element={<LoginScreen />} />
				<Route path='/shipping' element={<ShippingScreen />} />
				<Route path='/orderform' element={<OrderFormScreen />} />
				<Route path='/payment' element={<PaymentScreen />} />
				<Route path='/order/:id' element={<OrderScreen />} />
				<Route path='/placeorder' element={<PlaceOrderScreen />} />
				<Route path='/register' element={<RegisterScreen />} />
				<Route path='/profile' element={<ProfileScreen />} />
				<Route path='/aboutus' element={<AboutUsScreen />} />
				<Route path='/contactus' element={<ContactUsScreen />} />
				<Route path='/product/:id' element={<ProductScreen />} />
				<Route path='/cart/:id?' element={<CartScreen />} />
				<Route path='/admin/userlist' element={<UserListScreen />} />
				<Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
				<Route path='/admin/productlist' element={<ProductListScreen />} />
				<Route
					path='/admin/productlist/:pageNumber'
					element={<ProductListScreen />}
				/>
				<Route path='/admin/product/:id/edit' element={<ProductEditScreen />} />
				<Route path='/admin/orderlist' element={<OrderListScreen />} />

				<Route path='/search/:keyword' exact element={<HomeScreen />} />
				<Route path='/page/:pageNumber' exact element={<HomeScreen />} />
				<Route
					path='/search/:keyword/page/:pageNumber'
					exact
					element={<HomeScreen />}
				/>
			</Routes>
			<Footer />
		</>
	);
}

export default App;
