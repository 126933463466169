import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listProductDetails, updateProduct } from '../actions/productActions';
import {
	PRODUCT_UPDATE_RESET,
	PRODUCT_BEVERAGE_SIZE,
	PRODUCT_CATEGORIES,
} from '../constants/productConstants';
import '../css/ProductEditScreen.css';

const ProductEditScreen = () => {
	const history = useNavigate();
	const productId = useParams().id;
	const dispatch = useDispatch();

	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [description, setDescription] = useState('');
	const [image, setImage] = useState('');
	const [category, setCategory] = useState('');
	const [size, setSize] = useState('');
	const [countInStock, setCountInStock] = useState('');
	const [uploading, setUploading] = useState(false);

	const productDetails = useSelector((state) => state.productDetails);
	const { loading, error, product } = productDetails;

	const productUpdate = useSelector((state) => state.productUpdate);
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = productUpdate;

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: PRODUCT_UPDATE_RESET });
			history('/admin/productlist');
		} else {
			if (!product.name || product._id !== productId) {
				dispatch(listProductDetails(productId));
			} else {
				setName(product.name);
				setPrice(product.price);
				setDescription(product.description);
				setImage(product.image);
				setCategory(product.category);
				setSize(product.size);
				setCountInStock(product.countInStock);
			}
		}
	}, [dispatch, productId, product, history, successUpdate]);

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('image', file);
		setUploading(true);
		try {
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};
			const { data } = await axios.post('/api/upload', formData, config);
			setImage(data);
			setUploading(false);
		} catch (error) {
			console.error(error);
			setUploading(false);
		}
	};
	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(
			updateProduct({
				_id: productId,
				name,
				price,
				description,
				image,
				category,
				size,
				countInStock,
			})
		);
		history('/admin/productlist');
	};
	return (
		<>
			<div className='go-back-btn'>
				<a href='/admin/productlist'>
					<button>Go Back</button>
				</a>
			</div>

			<div className='product-edit-container'>
				<h1>Edit Product</h1>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<form onSubmit={submitHandler}>
						<div className='input-field'>
							<label>Name</label>
							<input
								type='name'
								placeholder='Enter name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							></input>
						</div>
						<div className='input-field'>
							<label>Price</label>
							<input
								type='number'
								placeholder='Enter price'
								value={price}
								onChange={(e) => setPrice(e.target.value)}
							></input>
						</div>
						<div className='input-field'>
							<label>Description</label>
							<textarea
								wrap='hard'
								rows={5}
								placeholder='Enter description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></textarea>
						</div>
						<div className='input-field'>
							<label>Image File</label>
							<strong>{image}</strong>
							<input
								type='file'
								id='image-file'
								custom
								onChange={uploadFileHandler}
							></input>
							{uploading && <Loader />}
						</div>
						<div className='input-field'>
							<label>Category</label>
							<select onChange={(e) => setCategory(e.target.value)}>
								{PRODUCT_CATEGORIES.map((x) =>
									x === product.category ? (
										<option key={x} value={x} selected>
											{x}
										</option>
									) : (
										<option key={x} value={x}>
											{x}
										</option>
									)
								)}{' '}
							</select>
						</div>
						<div className='input-field'>
							<label>Size</label>
							<select onChange={(e) => setSize(e.target.value)}>
								{PRODUCT_BEVERAGE_SIZE.map((x) =>
									x === product.size ? (
										<option key={x} value={x} selected>
											{x}
										</option>
									) : (
										<option key={x} value={x}>
											{x}
										</option>
									)
								)}{' '}
							</select>
						</div>
						<div className='input-field'>
							<label>Amount In Stock</label>
							<input
								type='number'
								placeholder='Enter amount in stock'
								value={countInStock}
								onChange={(e) => setCountInStock(e.target.value)}
							></input>
						</div>
						<button type='submit'>Update</button>
					</form>
				)}
			</div>
		</>
	);
};

export default ProductEditScreen;
