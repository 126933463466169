import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Link } from 'react-router-dom';

import SearchBox from './SearchBox';
import { logout } from '../actions/userActions';
import '../css/Header.css';

const Header = () => {
	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const nav = document.getElementById('nav');
	const navExpandHandler = () => {
		nav.style.height = '100%';
		nav.style.width = '280px';
		nav.style.background =
			'linear-gradient(90deg,rgb(240, 240, 240),silver,rgb(240, 240, 240))';
		nav.style.transition = 'all 0.5s ease';
	};
	const logoutHandler = (e) => {
		e.preventDefault();
		dispatch(logout());
	};
	return (
		<header>
			<nav className='nav-header'>
				<ul className='nav-items'>
					<li className='nav-item-logo'>
						<img
							onClick={navExpandHandler}
							src='/images/cairo_logo.jpg'
							alt='cairo_logo'
						/>
					</li>
					<li className='nav-item'>
						<span> Welcome, {userInfo ? userInfo.name : 'Guest'} </span>
					</li>
					<li className='nav-item'>
						<Routes>
							<Route
								render={({ history }) => <SearchBox history={history} />}
							/>
						</Routes>
					</li>
					<li className='nav-item'>
						<Link to='/'>
							<i className='fas fa-house'></i>
							<span>Home</span>
						</Link>
					</li>
					<li className='nav-item'>
						<Link to='/cart'>
							<i className='fas fa-shopping-cart'></i>
							<span>Cart</span>
						</Link>
					</li>

					{userInfo ? (
						<li className='nav-item'>
							<Link to='/profile'>
								<i className='fas fa-user'></i>
								<span>Profile</span>
							</Link>
						</li>
					) : (
						<li className='nav-item'>
							<Link to='/login'>
								<i className='fas fa-user'></i>
								<span>Sign In</span>
							</Link>
						</li>
					)}
					{userInfo && userInfo.isAdmin && (
						<div>
							<li className='nav-item'>
								<Link to='/admin/userlist'>
									<i className='fas fa-users'></i>
									<span>Users</span>
								</Link>
							</li>
							<li className='nav-item'>
								<Link to='/admin/productlist'>
									<i className='fas fa-wallet'></i>
									<span>Products</span>
								</Link>
							</li>
							<li className='nav-item'>
								<Link to='/admin/orderlist'>
									<i className='fas fa-tasks'></i>
									<span>Orders</span>
								</Link>
							</li>
						</div>
					)}
					<li className='nav-item'>
						<Link to='/aboutus'>
							<i className='fas fa-beer-mug-empty'></i>
							<span>About Us</span>
						</Link>
					</li>
					<li className='nav-item'>
						<Link to='/contactus'>
							<i className='fas fa-mobile-phone'></i>
							<span>Contact Us</span>
						</Link>
					</li>
					{userInfo && (
						<li className='logout'>
							<Link to='/' onClick={logoutHandler}>
								<i className='fas fa-sign-out-alt'></i>
								<span className='nav-item'>Logout</span>
							</Link>
						</li>
					)}
				</ul>
			</nav>
		</header>
	);
};

export default Header;
