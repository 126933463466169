import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Rating from '../components/Rating';
import {
	listProductDetails,
	createProductReview,
} from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants';
import '../css/ProductScreen.css';

const ProductScreen = () => {
	const match = useParams().id;
	const history = useNavigate();
	const dispatch = useDispatch();
	const [qty, setQty] = useState(1);
	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState(1);

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const productDetails = useSelector((state) => state.productDetails);
	const { loading, error, product } = productDetails;
	const productCreateReview = useSelector((state) => state.productCreateReview);
	const { success: successProductReview, error: errorProductReview } =
		productCreateReview;

	useEffect(() => {
		if (successProductReview === true) {
			alert('Review Submitted!');
			setRating(0);
			setComment('');
			dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
		}
		dispatch(listProductDetails(match));
	}, [dispatch, match, successProductReview]);

	const addToCartHandler = () => {
		history(`/cart/${match}?qty=${qty}`);
	};
	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(createProductReview(match, { rating, comment }));
		dispatch(listProductDetails(match));
	};
	return (
		<div className='product-screen-container'>
			<Helmet>
				<title>{product.name}</title>
				<meta name='description' content={product.description} />
			</Helmet>

			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<div className='product-details-container'>
					<div className='go-back-btn'>
						<a href='/'>
							<button>Go Back</button>
						</a>
					</div>
					<div className='product-container'>
						<div className='product-left-col'>
							<img src={product.image} alt={product.name} />
							<div className='product-rating'>
								<Rating
									value={product.rating}
									text={`${product.numReviews} reviews`}
								/>
							</div>
							<span>{product.description}</span>
						</div>
						<div className='product-right-col'>
							<span>{product.name}</span>
							<span>
								Price:
								<strong> KSh {product.price}</strong>
							</span>
							<div className='product-status'>
								<span>Status: </span>
								<strong>
									{product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
								</strong>
							</div>
							{product.countInStock > 0 && (
								<>
									<div className='product-qty-dropdown'>
										<span>Quantity: </span>
										<select onChange={(e) => setQty(e.target.value)}>
											{[...Array(product.countInStock).keys()].map((x) => (
												<option key={x + 1} value={x + 1}>
													{x + 1}
												</option>
											))}{' '}
										</select>
									</div>
									<div className='product-cart-btn'>
										<button onClick={addToCartHandler}>Add to Cart</button>
									</div>
								</>
							)}
						</div>
					</div>
					<div className='reviews-container'>
						<div className='reviews-left'>
							<span>PRODUCT REVIEWS:</span>
							{product.reviews.length === 0 && <Message>No Reviews</Message>}
							<div className='product-review-cards'>
								{product.reviews.map((review) => (
									<div className='review-card' key={review._id}>
										<strong>{review.name}</strong>
										<p>{review.createdAt.substring(0, 10)}</p>
										<Rating value={review.rating} text='' />
										<p>{review.comment}</p>
									</div>
								))}
							</div>
						</div>
						<div className='reviews-right'>
							<span>WRITE A REVIEW:</span>
							{errorProductReview && (
								<Message variant='danger'>{errorProductReview}</Message>
							)}
							{userInfo ? (
								<div className='rating-container'>
									<div className='star-widget'>
										<input
											type='radio'
											name='rate'
											id='rate-5'
											value={5}
											onChange={(e) => setRating(e.target.value)}
										/>
										<label htmlFor='rate-5' className='fas fa-star'></label>
										<input
											type='radio'
											name='rate'
											id='rate-4'
											value={4}
											onChange={(e) => setRating(e.target.value)}
										/>
										<label htmlFor='rate-4' className='fas fa-star'></label>
										<input
											type='radio'
											name='rate'
											id='rate-3'
											value={3}
											onChange={(e) => setRating(e.target.value)}
										/>
										<label htmlFor='rate-3' className='fas fa-star'></label>
										<input
											type='radio'
											name='rate'
											id='rate-2'
											value={2}
											onChange={(e) => setRating(e.target.value)}
										/>
										<label htmlFor='rate-2' className='fas fa-star'></label>
										<input
											type='radio'
											name='rate'
											id='rate-1'
											value={1}
											onChange={(e) => setRating(e.target.value)}
										/>
										<label htmlFor='rate-1' className='fas fa-star'></label>
										<form onSubmit={submitHandler}>
											<header></header>
											<div className='textarea'>
												<textarea
													cols='30'
													placeholder='Describe your experience...'
													onChange={(e) => setComment(e.target.value)}
												></textarea>
											</div>
											<div className='rating-btn'>
												<button type='submit'>Post</button>
											</div>
										</form>
									</div>
								</div>
							) : (
								<Message>
									Please <a href='/login'>Sign In</a> to write a review{' '}
								</Message>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ProductScreen;
