import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { login } from '../actions/userActions';
import '../css/LoginScreen.css';

const LoginScreen = () => {
	const location = useLocation();
	const history = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const { loading, error, userInfo } = userLogin;

	const redirect = location.search ? location.search.split('=')[1] : '/';

	useEffect(() => {
		if (userInfo && location.search.split('=')[1] !== 'orderform') {
			history(redirect);
		}
	}, [history, userInfo, redirect, location]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(login({ email, password }));
	};
	return (
		<div className='login-container'>
			<h1>Sign In</h1>
			{console.log(error)}
			{error && <Message variant='danger'>{error}</Message>}
			{loading && <Loader />}
			<form onSubmit={submitHandler}>
				<div className='login-content'>
					<div className='input-field'>
						<label>Email Address</label>
						<input
							type='email'
							placeholder='Enter email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></input>
					</div>
					<div className='input-field'>
						<label>Password</label>
						<input
							type='password'
							placeholder='Enter password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						></input>
					</div>
				</div>
				<div className='post-form-container'>
					<button type='submit'>Sign In</button>
					<div className='register-link'>
						New Customer?{' '}
						<a href={redirect ? `/register?redirect=${redirect}` : '/register'}>
							Register
						</a>
					</div>
				</div>
			</form>
		</div>
	);
};

export default LoginScreen;
