import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { addToCart, removeFromCart } from '../actions/cartActions';
import Message from '../components/Message';
import '../css/CartScreen.css';

const CartScreen = () => {
	const history = useNavigate();
	const productId = useParams().id;
	const location = useLocation();
	const qty = location.search ? Number(location.search.split('=')[1]) : 1;

	const dispatch = useDispatch();
	const cart = useSelector((state) => state.cart);
	const { cartItems } = cart;

	useEffect(() => {
		if (productId) {
			dispatch(addToCart(productId, qty));
		}
	}, [dispatch, productId, qty]);
	const removeFromCartHandler = (id) => {
		dispatch(removeFromCart(id));
	};
	const checkoutHandler = () => {
		history('/orderform');
	};
	return (
		<div className='cart-screen-container'>
			<div className='cart-container'>
				<h1>Shopping Cart</h1>
				{cartItems.length === 0 ? (
					<Message>Your cart is empty</Message>
				) : (
					<div className='cart-items' variant='flush'>
						{cartItems.map((item) => (
							<div className='cart-item' key={item.product}>
								<div className='cart-image'>
									<img src={item.image} alt={item.name} />
								</div>
								<div className='cart-product-name'>
									<a href={`/product/${item.product}`}>{item.name}</a>
								</div>
								<div className='cart-product-price'>KSh {item.price}</div>
								<form className='cart-product-qty'>
									<select
										value={item.qty}
										onChange={(e) =>
											dispatch(addToCart(item.product, Number(e.target.value)))
										}
									>
										{[...Array(item.countInStock).keys()].map((x) => (
											<option key={x + 1} value={x + 1}>
												{x + 1}
											</option>
										))}
									</select>
								</form>
								<div className='cart-remove-item-btn'>
									<button onClick={() => removeFromCartHandler(item.product)}>
										<i className='fas fa-trash'></i>
									</button>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			<div className='subtotal-container'>
				<div className='subtotal-items'>
					<div className='subtotal-item-amount'>
						<h2>
							Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
							items
						</h2>
						<span>
							KSh{' '}
							{cartItems.reduce((acc, item) => acc + item.qty * item.price, 0)}
						</span>
					</div>
					<div className='subtotal-item-btn'>
						<button
							type='button'
							disabled={cartItems.length === 0}
							onClick={checkoutHandler}
						>
							Proceed To Checkout
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartScreen;
