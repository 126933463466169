import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import Message from '../components/Message';
import Loader from '../components/Loader';
import { register } from '../actions/userActions';
import '../css/RegisterScreen.css';

const RegisterScreen = () => {
	const history = useNavigate();
	const location = useLocation();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState(null);

	const dispatch = useDispatch();

	const userRegister = useSelector((state) => state.userRegister);
	const { loading, error, userInfo } = userRegister;

	const redirect = location.search ? location.search.split('=')[1] : '/';

	useEffect(() => {
		if (Object.keys(userInfo).length > 0) {
			history(redirect);
		}
	}, [history, userInfo.name, userInfo, redirect]);

	const submitHandler = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Passwords do not match');
		} else {
			dispatch(register({ name, email, password }));
		}
	};
	return (
		<div className='register-container'>
			<h1>Sign Up</h1>
			{console.log(error)}
			{message && <Message variant='danger'>{message}</Message>}
			{error && <Message variant='danger'>{error}</Message>}
			{loading && <Loader />}
			<form onSubmit={submitHandler}>
				<div className='register-content'>
					<div className='input-field'>
						<label>Name</label>
						<input
							type='name'
							placeholder='Enter name'
							value={name}
							onChange={(e) => setName(e.target.value)}
						></input>
					</div>
				</div>
				<div className='input-field'>
					<label>Email Address</label>
					<input
						type='email'
						placeholder='Enter email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></input>
				</div>
				<div className='input-field'>
					<label>Password</label>
					<input
						type='password'
						placeholder='Enter password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></input>
				</div>
				<div className='input-field'>
					<label>Confirm Password</label>
					<input
						type='password'
						placeholder='Confirm password'
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					></input>
				</div>
				<div className='post-form-container'>
					<button type='submit'>Register</button>
					<div className='login-link'>
						Have an Account?{' '}
						<a href={redirect ? `/login?redirect=${redirect}` : '/login'}>
							Login
						</a>
					</div>
				</div>
			</form>
		</div>
	);
};

export default RegisterScreen;
